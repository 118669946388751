import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-4b4e25cb")
const _hoisted_1 = { class: "qp-menu" }
const _hoisted_2 = { style: {"font-size":"17px"} }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_menu, {
      router: "",
      "default-active": _ctx.defaultActive,
      onSelect: _ctx.selectMenu,
      "background-color": "#242832",
      "text-color": "#fff",
      "active-text-color": "#ffd04b"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuList, (item, index) => {
          return (_openBlock(), _createBlock(_component_el_sub_menu, {
            key: index,
            index: item.url
          }, {
            title: _withCtx(() => [
              _createElementVNode("i", {
                class: _normalizeClass(item.icon),
                style: {"font-size":"19px"}
              }, null, 2),
              _createElementVNode("span", _hoisted_2, _toDisplayString(item.title), 1)
            ]),
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (items, indexs) => {
                return (_openBlock(), _createBlock(_component_el_menu_item, {
                  key: indexs,
                  index: items.url,
                  style: {"font-size":"16px"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(items.title), 1)
                  ]),
                  _: 2
                }, 1032, ["index"]))
              }), 128))
            ]),
            _: 2
          }, 1032, ["index"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["default-active", "onSelect"])
  ]))
}