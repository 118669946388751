/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Options, Vue } from 'vue-class-component'
import Api from '@/api'
import { useStore } from 'vuex'
import { SuperVue } from '@/mixins/SuperVue'
import ReMenu from '@/components/re-menu/re-menu.vue'
import ReHeader from '@/components/re-header/re-header.vue'
import store from '@/store'

@Options({
  components: {
    ReMenu,
    ReHeader
  }
})
export default class Home extends SuperVue {
  created(): void {
    this.defaultActive = window.sessionStorage.getItem('activeIndex')
    if (window.sessionStorage.getItem('breadCrumb') != null) {
      this.breadCrumb = JSON.parse(window.sessionStorage.getItem('breadCrumb') as string)
    }
  }
  mounted() {
    const long_time_login = (count) => {
      try {
        let num_count = 0;
        let setIIndex = null as any;
        // eslint-disable-next-line no-return-assign
        document.onmousemove = () => {
          console.info('time reset');
          num_count = 0;
        }
        // document.onkeydown = () => {
        //   console.info('time reset');
        //   num_count = 0;
        // }
        if (setIIndex) clearInterval(setIIndex);
        setIIndex = setInterval(() => {
          num_count++;
          if (count === num_count) {
            alert('长时间未操作，退出系统')
            clearInterval(setIIndex)
            Api.http_outputLogin({}).then(() => { })
            this.$store.dispatch('LOGOUT')
            this.$router.push({ path: '/' })
          }
        }, 1000);
      } catch (error) {
        console.error('自动退出代码异常', error);
      }
    }
    long_time_login(15 * 60) // 执行 60秒不执行操作退出登录
  }
  // 头部收缩
  collapse(collapse) {
    this.isCollapse = collapse
  }

  // 面包屑
  breadCrumb = [{ name: '首页', to: '/home' }]
  beforeRouteUpdate(to, from, next): void {
    console.log('当前页面:', to.matched[1].components)
    this.breadCrumb[1] = { name: to.meta.title, to: '' }
    window.sessionStorage.setItem('breadCrumb', JSON.stringify(this.breadCrumb))
    next()
  }

  isCollapse: boolean = false
  store = useStore()
  defaultActive: string | null = ''
}
