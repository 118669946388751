
import { Options } from 'vue-class-component'
import { SuperVue } from '@/mixins/SuperVue'
import Utils from '@/utils'
import store from '@/store'

@Options({
  components: {}
})
export default class ReMenu extends SuperVue {
  // data
  defaultActive: string | null = null
  // computed
  get menuList() {
    // console.log(' store.state.access', store.state.access)
    return store.state.access
      ? Utils.list2tree(store.state.access, 'id', 'parent_id', 'children')
      : []
  }

  // 选择菜单
  selectMenu(index: string): void {
    // console.log('menuList', this.menuList)
    window.sessionStorage.setItem('activeIndex', index)
  }
}
