
import { Options } from 'vue-class-component'
import { SuperVue } from '@/mixins/SuperVue'
import { useStore } from 'vuex'
import store from '@/store'
import Api from '@/api/index'

@Options({
  components: {},
  props: {
    breadCrumb: {
      type: Array,
      default() {
        return []
      }
    }
  },
  inject: ['reload'], // 调用app.vue 的方法
  watch: {
    '$store.state.unReadCount'() {
      this.getMessageByToken()
    }
  }
})
export default class ReHeader extends SuperVue {
  created() {
    this.getReadCount()
    this.getMessageByToken()
  }
  reload!: () => void
  // props
  breadCrumb!: any[]
  breadcrumbClick() {
    this.reload()
    this.removeDialogName()
  }
  get curRoleId() {
    return Number(store.state.curRoleId)
  } // 当前选中角色下标
  get curRoleIdx() {
    return store.state.originAccess.findIndex((element) => {
      return element.id === Number(store.state.curRoleId)
    })
  } // 当前选中角色下标
  toggle(item, index: number) {
    // this.curRoleIdx = index;
    this.store.dispatch('SETACCESS', { access: item.access, id: item.id })
    const rolePages = [{ id: 69, name: 'pais' }];
    const idx = rolePages.findIndex((it) => it.id === item.id);
    if (idx > -1) {
      this.$router.push({ name: rolePages[idx].name });
    } else { this.$router.push({ name: 'home' }) }
  }
  // data
  store = useStore()
  isCollapse = false
  name = store.state.userInfo?.name
  // computed
  get roles() {
    return store.state.originAccess
  }
  // methods
  toPage(url: string): void {
    this.$router.push({ path: url })
  }
  logout(): void {
    Api.http_outputLogin({}).then((res) => {
      this.store.dispatch('LOGOUT')
      this.$router.push({ path: '/' })
    })
  }
  getReadCount() {
    Api.http_getReadCount({}).then((res) => {
      store.state.unReadCount = res.data
    })
  }
  unReadList = [] as any
  getMessageByToken() {
    Api.http_getMessageByToken({
      offset: 0,
      length: 3,
      is_read: 0
    }).then((res) => {
      this.unReadList = res.data.data
    })
  }
}
